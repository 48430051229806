<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">RTO</div>
    </div>
    <div class="" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Registration Number<span class="text-danger">*</span></label>
                            <input type="text" v-model="regnumber" class="form-control text-uppercase"
                                placeholder="Enter Registration Number" maxlength="10" :disabled="inputdisabled"
                                minlength="6" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12" v-if="!sendotpbtnhide">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '105px' }"
                                @click="checkMobileinSystem(regnumber)" :disabled="regnumber.length < 6 || btnloader">
                                <span v-if="!btnloader">Get Details</span>
                                <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '378px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Registration Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.rc_number ? votergovdata.rc_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Registration Date</label>
                                            <div class="from-label-value">
                                                {{ format_date(votergovdata.registration_date) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Owner Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.owner_name ? votergovdata.owner_name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider type="dashed" class="mt-0 mb-2" />
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Father Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.father_name ? votergovdata.father_name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Mobile Number</label>
                                            <div class="from-label-value" v-if="votergovdata.mobile_number">
                                                +91-{{ votergovdata.mobile_number }}
                                            </div>
                                            <div class="from-label-value" v-else>
                                                N/A
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider type="dashed" class="mt-0 mb-2" />
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Current Address</label>
                                    <div class="from-label-value text-capitalize">
                                        {{ votergovdata.present_address ? votergovdata.present_address : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <Divider type="dashed" class="mt-0 mb-2" />
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Permanent Address</label>
                                    <div class="from-label-value text-capitalize">
                                        {{ votergovdata.permanent_address ? votergovdata.permanent_address : "N/A" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Vehicle Category</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.vehicle_category ? votergovdata.vehicle_category : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Vehicle Chasis Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.vehicle_chasi_number ? votergovdata.vehicle_chasi_number :
                                                    "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Vehicle Engine Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.vehicle_engine_number ? votergovdata.vehicle_engine_number :
                                                    "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Maker Description</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.maker_description ? votergovdata.maker_description : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Maker Model</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.maker_model ? votergovdata.maker_model : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Body Type</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.body_type ? votergovdata.body_type : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Fuel Type</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.fuel_type ? votergovdata.fuel_type : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Color</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.color ? votergovdata.color : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Norms Type</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.norms_type ? votergovdata.norms_type : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Fit Up To</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(votergovdata.fit_up_to) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Financer</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.financer ? votergovdata.financer : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Insurance Company</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.insurance_company ? votergovdata.insurance_company : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Insurance Policy Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.insurance_policy_number ?
                                                    votergovdata.insurance_policy_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Insurance Upto</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(votergovdata.insurance_upto) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Manufacturing Date</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.manufacturing_date ? votergovdata.manufacturing_date : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Manufacturing Date Formatted</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.manufacturing_date_formatted ?
                                                    votergovdata.manufacturing_date_formatted : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Registered At</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.registered_at ? votergovdata.registered_at : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Latest By</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(votergovdata.latest_by) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Tax Upto</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.tax_upto ? votergovdata.tax_upto : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Tax Paid Upto</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.tax_paid_upto ? votergovdata.tax_paid_upto : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Cubic Capacity</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.cubic_capacity ? votergovdata.cubic_capacity : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Vehicle Gross Weight</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.vehicle_gross_weight ? votergovdata.vehicle_gross_weight :
                                                    "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">No. Cylinders</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.no_cylinders ? votergovdata.no_cylinders : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Seat Capacity</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.seat_capacity ? votergovdata.seat_capacity : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Sleeper Capacity</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.sleeper_capacity ? votergovdata.sleeper_capacity : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Standing Capacity</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.standing_capacity ? votergovdata.standing_capacity : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Wheelbase</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.wheelbase ? votergovdata.wheelbase : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Vehicle Category Description</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.vehicle_category_description ?
                                                    votergovdata.vehicle_category_description : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Pucc Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.pucc_number ? votergovdata.pucc_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Pucc Upto</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(votergovdata.pucc_upto) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Permit Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.permit_number ? votergovdata.permit_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Permit Valid From</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.permit_valid_from ? votergovdata.permit_valid_from : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Permit Valid Upto</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.permit_valid_upto ? votergovdata.permit_valid_upto : "N/A"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">NOC Details</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.noc_details ? votergovdata.noc_details : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Owner Number</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.owner_number ? votergovdata.owner_number : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <Divider type="dashed" class="mt-0 mb-2" />
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">RC Status</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ votergovdata.rc_status ? votergovdata.rc_status : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            regnumber: '',
            votergovdata: '',
            sendotpbtnhide: false,
            btnloader: false,
            mobiledetailshowflag: false,
            inputdisabled: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        checkMobileinSystem(e) {
            this.disabledinput = true;
            this.btnloader = true;
            this.ApiService.getRCdetails({ rc_number: e }).then((data) => {
                if (data.status === true) {
                    this.btnloader = false;
                    this.votergovdata = data.data;
                    this.mobiledetailshowflag = true;
                    this.sendotpbtnhide = true;
                    this.inputdisabled = true;
                    this.clearbtnflag = true;
                } else {
                    this.mobiledetailshowflag = false;
                    this.btnloader = false;
                    this.inputdisabled = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });

        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.regnumber = '';
            this.votergovdata = '';
            this.sendotpbtnhide = false;
            this.btnloader = false;
            this.mobiledetailshowflag = false;
            this.inputdisabled = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>